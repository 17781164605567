<template>
  <el-dialog
    :model-value="dialogVisible"
    :title="content.parameName"
    width="300px"
    @opened="handleOpen"
    @close="handleClose"
    :close-on-click-modal="false"
    draggable
  >
    <el-input v-model="setValue" />
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { Plus, Edit, Delete } from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { ref } from 'vue'
import { isNull } from '@/utitls/validate'
import { SetParamesValue } from '@/api/DataRecord'
const props = defineProps({
  content: {
    type: Array,
    required: true
  },
  deviceID: {
    type: String,
    default: ''
  },
  token: {
    type: String,
    default: ''
  }
})
const dialogVisible = ref(false)
const content = ref([])
const deviceID = ref('')
const setValue = ref('')
const emits = defineEmits(['update:modelValue'])
const handleOpen = () => {
  content.value = JSON.parse(JSON.stringify(props.content))
  deviceID.value =
    props.deviceID == '' ? content.value.deviceID : props.deviceID
}
const handleClose = () => {
  emits('update:modelValue', false)
}
const handleConfirm = () => {
  if (isNull(setValue.value)) {
    ElMessage({
      message: '参数值不能为空',
      type: 'error'
    })
  } else {
    let info = [
      {
        deviceID: deviceID.value,
        parameName: content.value.parameName,
        value: setValue.value
      }
    ]
    SetParamesValue(
      {
        info: JSON.stringify(info)
      },
      { Authorization: props.token }
    )
      .then((res) => {
        ElMessage({
          message: `设置成功`,
          type: 'success'
        })
        emits('update:modelValue', false)
      })
      .catch((err) => {
        ElMessage({
          message: `设置失败:${err}`,
          type: 'error'
        })
        console.log(err)
      })
  }
}
</script>

<style lang="scss" scoped>
:deep(.el-table--fit) {
  margin-top: 10px;
}
:deep(.el-input__wrapper) {
  width: 100%;
  background-color: #0a1441 !important;
}
:deep(.el-input__inner) {
  color: white;
}
</style>

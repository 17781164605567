<template>
  <div
    :style="`float:left;margin:0.25% 1%;width:${
      (100 - 2 * radio) / radio
    }%;height:${95 / radio}%`"
  >
    <iframe
      :src="link"
      frameborder="0"
      id="myIframe"
      style="width: 100%; height: 100%"
    ></iframe>
  </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref, watch } from 'vue'
const props = defineProps({
  link: {
    type: String
  },
  radio: {
    type: Number,
    default: 1
  },
  closeSendInfo: {
    type: String
  }
})
onMounted(() => {
  link.value = props.link
  radio.value = props.radio
})
onBeforeUnmount(() => {
  if (props.closeSendInfo != 'undefined' && props.closeSendInfo != '') {
    document
      .getElementById('myIframe')
      .contentWindow.postMessage(props.closeSendInfo, '*')
    console.log(11)
  }
})
const dialogVisible = ref(false)
const link = ref('')
const radio = ref(1)
watch(
  () => props.radio,
  (newVal, oldValue) => {
    radio.value = newVal
  }
)
</script>

<style lang="scss" scoped></style>

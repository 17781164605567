// 画布初始化配置
export const InitConnecting = {
    router: {
        name: 'orth',
        args: {
            padding: 1
        }
    },
    connector: {
        name: 'rounded',
        args: {
            radius: 8
        }
    },
    anchor: 'center',
    connectionPoint: 'anchor',
    snap: false
}

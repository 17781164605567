<template>
  <el-dialog
    :model-value="dialogVisible"
    :title="content.title"
    :width="`${content.width}px`"
    @opened="handleOpen"
    @close="handleClose"
    destroy-on-close
    :modal="false"
    :close-on-click-modal="false"
    draggable
  >
    <iframe
      :src="content.link"
      frameborder="0"
      :style="`height:${content.height}px;width:100%`"
      id="myIframe"
    ></iframe>
  </el-dialog>
</template>

<script setup>
import { ref } from 'vue'
const props = defineProps({
  title: {
    type: String
  },
  width: {
    type: Number,
    default: 800
  },
  height: {
    type: Number,
    default: 600
  },
  link: {
    type: String
  },
  closeSendInfo: {
    type: String
  }
})

const dialogVisible = ref(false)
const content = ref({})

const emits = defineEmits(['update:modelValue'])
const handleOpen = () => {
  content.value = {
    title: props.title,
    width: props.width,
    height: props.height,
    link: props.link,
    closeSendInfo: props.closeSendInfo
  }
}
const handleClose = () => {
  if (
    typeof content.value.closeSendInfo != 'undefined' &&
    content.value.closeSendInfo != ''
  ) {
    document
      .getElementById('myIframe')
      .contentWindow.postMessage(content.value.closeSendInfo, '*')
  }
  emits('update:modelValue', false)
}
</script>

<style lang="scss" scoped></style>

<template>
  <div style="width: 100%; height: 100%" ref="app">
    <!-- <div class="operate" @click="rotate">旋转</div> -->
    <div ref="refContainer" style="width: 100%; height: 100%"></div>
    <div
      v-if="GraphIframeDialogInfo.visible"
      :style="`
        position: absolute;
        top: 10px;
        right: 10px;
        width: ${GraphIframeDialogInfo.content.form.width}px;
        height: ${GraphIframeDialogInfo.content.form.height}px;
      `"
    >
      <el-alert
        :title="GraphIframeDialogInfo.content.form.title"
        style="width: 100%; height: 100%"
        @close="handleCloseAlert"
      >
        <template #default>
          <GraphAlert
            v-for="(item, index) in GraphIframeDialogInfo.content.rules"
            :key="index"
            :link="item.link"
            :radio="radio"
            :closeSendInfo="item.closeSendInfo"
          />
        </template>
      </el-alert>
    </div>
  </div>
  <div>
    <IframeDialog
      v-if="IframeDialogInfo.visible"
      v-model="IframeDialogInfo.visible"
      :title="IframeDialogInfo.content.title"
      :width="IframeDialogInfo.content.width"
      :height="IframeDialogInfo.content.height"
      :link="IframeDialogInfo.content.link"
      :closeSendInfo="IframeDialogInfo.content.closeSendInfo"
    ></IframeDialog>
    <SetValueDialog
      v-if="SetValueDialogInfo.visible"
      v-model="SetValueDialogInfo.visible"
      :content="SetValueDialogInfo.content"
      :deviceID="deviceID"
      :token="token"
    ></SetValueDialog>
  </div>
</template>

<script setup>
import { Graph } from '@antv/x6'
import { ref, onMounted, reactive, onUnmounted, watch } from 'vue'
import { InitConnecting } from './assets/InitGraphInfo'
import insertCss from 'insert-css'
import { GetScenesInfo } from '@/api/Scenes'
import { useRoute, useRouter } from 'vue-router'
import { ElNotification, ElMessageBox, ElMessage } from 'element-plus'
import { isNull } from '@/utitls/validate'
import { GetSCADARealTimeData, SetParamesValue } from '@/api/DataRecord'
import IframeDialog from './components/iframeDialog.vue'
import SetValueDialog from './components/setValueDialog.vue'
import GraphAlert from './components/graphAlert.vue'
const props = defineProps({
  code: {
    type: String,
    default: ''
  },
  deviceID: {
    type: String,
    default: ''
  },
  type: {
    type: Number,
    default: 0
  }
})
const route = useRoute()
const router = useRouter()
const refContainer = ref(null)
const app = ref(null)
var dataCells = [] //配置了业务数据的点
var pointPrames = [] //页面用到的数据点位名称
var pointDataCache = [] //页面用的数据点缓存{名称,值}
var graph = reactive({})
const radio = ref(1)
const IframeDialogInfo = ref({
  visible: false,
  content: {}
})
const GraphIframeDialogInfo = ref({
  visible: false,
  content: {
    form: {
      height: 0,
      width: 0
    }
  }
})
const SetValueDialogInfo = ref({
  visible: false,
  content: []
})
var intervalId = ref(0)
const notiInfo = ref('')
const init = () => {
  graph = new Graph({
    container: refContainer.value,
    interacting: false, //禁止交互
    connecting: {
      ...InitConnecting
    },
    autoResize: true,
    panning: {
      enabled: true
    }, // 普通画布(未开启 scroller 模式)通过开启 panning 选项来支持拖拽平移。
    mousewheel: {
      // 鼠标滚轮缩放
      enabled: true,
      // 是否为全局事件，设置为 true 时滚轮事件绑定在 document 上，否则绑定在画布容器上。默认为 false。
      global: true
      // modifiers: ['ctrl']
    }
  })
  //节点事件
  //鼠标按下事件
  graph.on('node:mousedown', ({ node }) => {
    if (typeof node.data != 'undefined' && node.data.hasMousedown) {
      handleNodeEnvent(node.data.MousedownRule)
    }
  })
  //鼠标抬起事件
  graph.on('node:mouseup', ({ node }) => {
    if (typeof node.data != 'undefined' && node.data.hasMouseup) {
      handleNodeEnvent(node.data.MouseupRule)
    }
  })
  //点击事件
  graph.on('node:click', ({ node }) => {
    if (typeof node.data != 'undefined' && node.data.hasClick) {
      handleNodeEnvent(node.data.ClickRule)
    }
  })
  //双击
  graph.on('node:dblclick', ({ node }) => {
    if (typeof node.data != 'undefined' && node.data.hasDblClick) {
      handleNodeEnvent(node.data.DblClickRule)
    }
  })
  //画布大小改变
  graph.on('resize', ({ width, height }) => {
    graph.scaleContentToFit()
    graph.centerContent()
  })
  //空白处双击
  // graph.on('blank:dblclick', ({ e, x, y }) => {
  //   let full = document.fullscreenElement
  //   console.log(full)
  //   if (full) {
  //     document.exitFullscreen()
  //   } else {
  //     document.documentElement.requestFullscreen()
  //   }
  // })
}
var graphInfo = reactive({})
var regUrl = new RegExp('@@@', 'g') //g,表示全部替换
//节点事件处理
const handleNodeEnvent = (enventRule) => {
  if (typeof enventRule != 'undefined') {
    if (enventRule.operatType == 1) {
      //打开外链
      window.open(enventRule.link, '_blank')
    } else if (enventRule.operatType == 2) {
      //打开内链
      if (enventRule.openType == 0) {
        //页面切换
        // router.push(enventRule.link)
        scenceCode = enventRule.link
        GraphIframeDialogInfo.value.visible = false
        IframeDialogInfo.visible = false
        ElNotification.closeAll()
        clearInterval(intervalId.value)
        initGraph(false)
      } else if (enventRule.openType == 1)
        //打开新页面
        window.open(enventRule.link, '_blank')
      else if (enventRule.openType == 2) {
        //弹窗
        IframeDialogInfo.value.content = {
          title: enventRule.title,
          width: parseInt(enventRule.width),
          height: parseInt(enventRule.height),
          link: enventRule.link,
          closeSendInfo: enventRule.closeSendInfo
        }
        IframeDialogInfo.value.visible = true
      }
    } else if (enventRule.operatType == 0) {
      let rules = enventRule.rules
      if (typeof rules != 'undefined' && rules.length > 0) {
        ElMessageBox.confirm('确认要进行当前操作吗？', '提醒', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let info = []
          rules.forEach((item) => {
            let id = sceneType.value == 1 ? deviceID.value : item.deviceID
            info.push({
              deviceID: id,
              parameName: item.parameName,
              value: item.value
            })
          })
          SetParamesValue(
            {
              info: JSON.stringify(info)
            },
            { Authorization: token }
          )
            .then((res) => {
              ElMessage({
                showClose: true,
                type: 'success',
                message: res.Msg,
                duration: 1000
              })
              getPointData()
            })
            .catch((err) => {
              ElMessage({
                message: `操作失败:${err}`,
                type: 'error'
              })
              console.log(err)
            })
        })
      }
    } else if (enventRule.operatType == 3) {
      let id = sceneType.value == 1 ? deviceID.value : enventRule.deviceID
      SetValueDialogInfo.value.content = {
        deviceID: id,
        deviceName: enventRule.deviceName,
        parameName: enventRule.parameName
      }
      SetValueDialogInfo.value.visible = true
    }
  }
}

//页面设置
const initGraphInfo = (cesllInfo) => {
  graph.fromJSON(cesllInfo)
}
const resetGraph = () => {
  if (graphInfo.showGrid) graph.showGrid()
  else graph.hideGrid()
  graph.drawGrid({
    type: graphInfo.gridType,
    args: {
      color: graphInfo.gridColor, // 网格线/点颜色
      thickness: parseFloat(graphInfo.gridThickness) // 网格线宽度
    }
  })
  graph.setGridSize(parseFloat(graphInfo.gridSize))
  graph.drawBackground({
    color: graphInfo.bgColor,
    image: graphInfo.useBgImage ? graphInfo.bgImage : undefined,
    position: graphInfo.bgPosition,
    size: graphInfo.bgSize,
    repeat: graphInfo.bgRepeat,
    opacity: graphInfo.bgOpacity
  })
  let width = parseInt(app.value.offsetWidth)
  let height = parseInt(app.value.offsetHeight)
  graph.resize(width, height)
  graph.scaleContentToFit()
  graph.centerContent()
}
const cleanData = (cesllInfo) => {
  if (graphInfo.hasTip && graphInfo.tipRules) {
    graphInfo.tipRules.forEach((item) => {
      let id = sceneType.value == 1 ? deviceID.value : item.deviceID
      if (
        pointPrames.findIndex(
          (z) => z.deviceID == id && z.parameName == item.parameName
        ) == -1
      ) {
        pointPrames.push({
          deviceID: id,
          parameName: item.parameName
        })
        pointDataCache.push({
          deviceID: id,
          parameName: item.parameName,
          value: ''
        })
      }
    })
  }
  if (
    typeof graphInfo.hasGraphDialog != 'undefined' &&
    typeof graphInfo.dialogRules != 'undefined' &&
    typeof graphInfo.dialogRules.rules != 'undefined' &&
    graphInfo.dialogRules.rules.length > 0
  ) {
    graphInfo.dialogRules.rules.forEach((item) => {
      let id = sceneType.value == 1 ? deviceID.value : item.deviceID
      if (
        pointPrames.findIndex(
          (z) => z.deviceID == id && z.parameName == item.parameName
        ) == -1
      ) {
        pointPrames.push({
          deviceID: id,
          parameName: item.parameName
        })
        pointDataCache.push({
          deviceID: id,
          parameName: item.parameName,
          value: ''
        })
      }
    })
  }
  cesllInfo.cells.forEach((item) => {
    if (item.hasOwnProperty('data')) {
      try {
        dataCells.push({
          id: item.id,
          data: item.data,
          shape: item.shape,
          dataPoints: []
        })
      } catch (e) {
        console.log(item)
      }
    }
  })
  if (dataCells.length > 0) {
    dataCells.forEach((cell) => {
      if (cell.shape == 'edge') {
        //边
        if (cell.data.hasShowHide && cell.data.ShowHideRule) {
          cell.data.ShowHideRule.forEach((item) => {
            let id = sceneType.value == 1 ? deviceID.value : item.deviceID
            if (
              pointPrames.findIndex(
                (z) => z.deviceID == id && z.parameName == item.parameName
              ) == -1
            ) {
              pointPrames.push({
                deviceID: id,
                parameName: item.parameName
              })
              pointDataCache.push({
                deviceID: id,
                parameName: item.parameName,
                value: ''
              })
            }
            if (
              cell.dataPoints.findIndex(
                (z) => z.deviceID == id && z.parameName == item.parameName
              ) == -1
            )
              cell.dataPoints.push({
                deviceID: id,
                parameName: item.parameName
              })
          })
        }
        if (cell.data.hasFlow && cell.data.FlowRule) {
          cell.data.FlowRule.forEach((item) => {
            let id = sceneType.value == 1 ? deviceID.value : item.deviceID
            if (
              pointPrames.findIndex(
                (z) => z.deviceID == id && z.parameName == item.parameName
              ) == -1
            ) {
              pointPrames.push({
                deviceID: id,
                parameName: item.parameName
              })
              pointDataCache.push({
                deviceID: id,
                parameName: item.parameName,
                value: ''
              })
            }
            if (
              cell.dataPoints.findIndex(
                (z) => z.deviceID == id && z.parameName == item.parameName
              ) == -1
            )
              cell.dataPoints.push({
                deviceID: id,
                parameName: item.parameName
              })
          })
        }
        if (cell.data.hasChangeColor && cell.data.ChangeColorRule) {
          cell.data.ChangeColorRule.forEach((item) => {
            let id = sceneType.value == 1 ? deviceID.value : item.deviceID
            if (
              pointPrames.findIndex(
                (z) => z.deviceID == id && z.parameName == item.parameName
              ) == -1
            ) {
              pointPrames.push({
                deviceID: id,
                parameName: item.parameName
              })
              pointDataCache.push({
                deviceID: id,
                parameName: item.parameName,
                value: ''
              })
            }
            if (
              cell.dataPoints.findIndex(
                (z) => z.deviceID == id && z.parameName == item.parameName
              ) == -1
            )
              cell.dataPoints.push({
                deviceID: id,
                parameName: item.parameName
              })
          })
        }
      } //节点
      else {
        if (cell.data.hasShowHide && cell.data.ShowHideRule) {
          cell.data.ShowHideRule.forEach((item) => {
            let id = sceneType.value == 1 ? deviceID.value : item.deviceID
            if (
              pointPrames.findIndex(
                (z) => z.deviceID == id && z.parameName == item.parameName
              ) == -1
            ) {
              pointPrames.push({
                deviceID: id,
                parameName: item.parameName
              })
              pointDataCache.push({
                deviceID: id,
                parameName: item.parameName,
                value: ''
              })
            }
            if (
              cell.dataPoints.findIndex(
                (z) => z.deviceID == id && z.parameName == item.parameName
              ) == -1
            )
              cell.dataPoints.push({
                deviceID: id,
                parameName: item.parameName
              })
          })
        }
        if (cell.data.hasRotate && cell.data.RotateRule) {
          cell.data.RotateRule.forEach((item) => {
            let id = sceneType.value == 1 ? deviceID.value : item.deviceID
            if (
              pointPrames.findIndex(
                (z) => z.deviceID == id && z.parameName == item.parameName
              ) == -1
            ) {
              pointPrames.push({
                deviceID: id,
                parameName: item.parameName
              })
              pointDataCache.push({
                deviceID: id,
                parameName: item.parameName,
                value: ''
              })
            }
            if (
              cell.dataPoints.findIndex(
                (z) => z.deviceID == id && z.parameName == item.parameName
              ) == -1
            )
              cell.dataPoints.push({
                deviceID: id,
                parameName: item.parameName
              })
          })
        }
        if (
          cell.data.hasBind &&
          cell.data.BindRule &&
          cell.data.BindRule.parameName
        ) {
          let id =
            sceneType.value == 1 ? deviceID.value : cell.data.BindRule.deviceID
          if (
            pointPrames.findIndex(
              (z) =>
                z.deviceID == id &&
                z.parameName == cell.data.BindRule.parameName
            ) == -1
          ) {
            pointPrames.push({
              deviceID: id,
              parameName: cell.data.BindRule.parameName
            })
            pointDataCache.push({
              deviceID: id,
              parameName: cell.data.BindRule.parameName,
              value: ''
            })
          }
          if (
            cell.dataPoints.findIndex(
              (z) =>
                z.deviceID == id &&
                z.parameName == cell.data.BindRule.parameName
            ) == -1
          )
            cell.dataPoints.push({
              deviceID: id,
              parameName: cell.data.BindRule.parameName
            })
        }
        if (cell.data.hasChangeColor && cell.data.ChangeColorRule) {
          cell.data.ChangeColorRule.forEach((item) => {
            let id = sceneType.value == 1 ? deviceID.value : item.deviceID
            if (
              pointPrames.findIndex(
                (z) => z.deviceID == id && z.parameName == item.parameName
              ) == -1
            ) {
              pointPrames.push({
                deviceID: id,
                parameName: item.parameName
              })
              pointDataCache.push({
                deviceID: id,
                parameName: item.parameName,
                value: ''
              })
            }
            if (
              cell.dataPoints.findIndex(
                (z) => z.deviceID == id && z.parameName == item.parameName
              ) == -1
            )
              cell.dataPoints.push({
                deviceID: id,
                parameName: item.parameName
              })
          })
        }
        if (cell.data.hasChangeWH && cell.data.ChangeWHRule) {
          cell.data.ChangeWHRule.forEach((item) => {
            let id = sceneType.value == 1 ? deviceID.value : item.deviceID
            if (
              pointPrames.findIndex(
                (z) => z.deviceID == id && z.parameName == item.parameName
              ) == -1
            ) {
              pointPrames.push({
                deviceID: id,
                parameName: item.parameName
              })
              pointDataCache.push({
                deviceID: id,
                parameName: item.parameName,
                value: ''
              })
            }
            if (
              cell.dataPoints.findIndex(
                (z) => z.deviceID == id && z.parameName == item.parameName
              ) == -1
            )
              cell.dataPoints.push({
                deviceID: id,
                parameName: item.parameName
              })
          })
          //给需要变形的Node添加初始的宽高
          let node = graph.getCellById(cell.id)
          let data = node.data
          let size = node.size()
          data.baseHeight = parseFloat(size.height)
          data.baseWidth = parseFloat(size.width)
          node.setData(data, { overwrite: true, silent: true })
        }
      }
    })
  }
}
var graphDialogLinks = '1'
const getPointData = () => {
  if (pointPrames.length > 0) {
    GetSCADARealTimeData(
      { items: JSON.stringify(pointPrames) },
      { Authorization: token }
    )
      .then((res) => {
        let tipMessage = ''
        let graphIframeDialogContent = {
          rules: [],
          form: {}
        }
        let graphDialogLinks_New = ''
        res.Data.forEach((item) => {
          if (graphInfo.hasTip && graphInfo.tipRules) {
            let tipRules = graphInfo.tipRules.filter(
              (z) =>
                ((sceneType.value != 1 && z.deviceID == item.deviceID) ||
                  sceneType.value == 1) &&
                z.parameName == item.parameName
            )
            if (tipRules.length > 0) {
              tipRules.forEach((rule) => {
                let value =
                  (item.value + '').toLowerCase() == 'true'
                    ? 1
                    : (item.value + '').toLowerCase() == 'false'
                    ? 0
                    : Number(item.value)
                let maxValue =
                  (rule.maxValue + '').toLowerCase() == 'true'
                    ? 1
                    : (rule.maxValue + '').toLowerCase() == 'false'
                    ? 0
                    : Number(rule.maxValue)
                let minValue =
                  (rule.minValue + '').toLowerCase() == 'true'
                    ? 1
                    : (rule.minValue + '').toLowerCase() == 'false'
                    ? 0
                    : Number(rule.minValue)
                if (maxValue >= value && minValue <= value) {
                  tipMessage += rule.tipInfo.replace('###', item.value) + '\n'
                }
              })
            }
          }

          if (
            typeof graphInfo.hasGraphDialog != 'undefined' &&
            typeof graphInfo.dialogRules != 'undefined' &&
            typeof graphInfo.dialogRules.rules != 'undefined'
          ) {
            let graphIframeDialogRules = graphInfo.dialogRules.rules.filter(
              (z) =>
                ((sceneType.value != 1 && z.deviceID == item.deviceID) ||
                  sceneType.value == 1) &&
                z.parameName == item.parameName
            )
            if (graphIframeDialogRules.length > 0) {
              graphIframeDialogRules.forEach((rule) => {
                let value =
                  (item.value + '').toLowerCase() == 'true'
                    ? 1
                    : (item.value + '').toLowerCase() == 'false'
                    ? 0
                    : Number(item.value)
                let maxValue =
                  (rule.maxValue + '').toLowerCase() == 'true'
                    ? 1
                    : (rule.maxValue + '').toLowerCase() == 'false'
                    ? 0
                    : Number(rule.maxValue)
                let minValue =
                  (rule.minValue + '').toLowerCase() == 'true'
                    ? 1
                    : (rule.minValue + '').toLowerCase() == 'false'
                    ? 0
                    : Number(rule.minValue)
                if (maxValue >= value && minValue <= value) {
                  graphIframeDialogContent.rules.push({
                    link: rule.link,
                    closeSendInfo: rule.closeSendInfo
                  })
                  graphDialogLinks_New += rule.link
                }
              })
            }
          }

          for (let i = 0; i < pointDataCache.length; i++) {
            if (
              pointDataCache[i].deviceID === item.deviceID &&
              pointDataCache[i].parameName === item.parameName &&
              pointDataCache[i].value + '' != item.value + ''
            ) {
              pointDataCache[i].value = item.value
              let cells = dataCells.filter(
                (z) =>
                  z.dataPoints.findIndex(
                    (z) =>
                      ((sceneType.value != 1 && z.deviceID == item.deviceID) ||
                        sceneType.value == 1) &&
                      z.parameName == item.parameName
                  ) >= 0
              )
              if (cells.length > 0) {
                cells.forEach((cell) => {
                  if (cell.shape == 'edge') {
                    //边
                    if (
                      cell.data.hasOwnProperty('hasShowHide') &&
                      cell.data.hasShowHide &&
                      cell.data.hasOwnProperty('ShowHideRule') &&
                      cell.data.ShowHideRule
                    ) {
                      let value =
                        (item.value + '').toLowerCase() == 'true'
                          ? 1
                          : (item.value + '').toLowerCase() == 'false'
                          ? 0
                          : Number(item.value)
                      let rules = cell.data.ShowHideRule.filter(
                        (z) =>
                          ((sceneType.value != 1 &&
                            z.deviceID == item.deviceID) ||
                            sceneType.value == 1) &&
                          z.parameName == item.parameName
                      )
                      if (rules != null && rules.length > 0)
                        rules.forEach((rule) => {
                          let maxValue =
                            (rule.maxValue + '').toLowerCase() == 'true'
                              ? 1
                              : (rule.maxValue + '').toLowerCase() == 'false'
                              ? 0
                              : Number(rule.maxValue)
                          let minValue =
                            (rule.minValue + '').toLowerCase() == 'true'
                              ? 1
                              : (rule.minValue + '').toLowerCase() == 'false'
                              ? 0
                              : Number(rule.minValue)
                          if (maxValue >= value && minValue <= value) {
                            let edge = graph.getCellById(cell.id)
                            edge.visible = rule.type == '显示'
                          }
                        })
                    }
                    if (
                      cell.data.hasOwnProperty('hasFlow') &&
                      cell.data.hasFlow &&
                      cell.data.hasOwnProperty('FlowRule') &&
                      cell.data.FlowRule
                    ) {
                      let value =
                        (item.value + '').toLowerCase() == 'true'
                          ? 1
                          : (item.value + '').toLowerCase() == 'false'
                          ? 0
                          : Number(item.value)
                      let rules = cell.data.FlowRule.filter(
                        (z) =>
                          ((sceneType.value != 1 &&
                            z.deviceID == item.deviceID) ||
                            sceneType.value == 1) &&
                          z.parameName == item.parameName
                      )
                      if (rules != null && rules.length > 0)
                        rules.forEach((rule) => {
                          let maxValue =
                            (rule.maxValue + '').toLowerCase() == 'true'
                              ? 1
                              : (rule.maxValue + '').toLowerCase() == 'false'
                              ? 0
                              : Number(rule.maxValue)
                          let minValue =
                            (rule.minValue + '').toLowerCase() == 'true'
                              ? 1
                              : (rule.minValue + '').toLowerCase() == 'false'
                              ? 0
                              : Number(rule.minValue)
                          if (maxValue >= value && minValue <= value) {
                            let edge = graph.getCellById(cell.id)
                            if (rule.type == '正向流动') {
                              edge.attr('line/style', {
                                animation: 'ant-line 30s infinite linear '
                              })
                            } else if (rule.type == '反向流动') {
                              edge.attr('line/style', {
                                animation:
                                  'ant-line 30s infinite linear reverse'
                              })
                            } else if (rule.type == '停止流动') {
                              edge.attr('line/style', null)
                            }
                          }
                        })
                    }
                    if (
                      cell.data.hasOwnProperty('hasChangeColor') &&
                      cell.data.hasChangeColor &&
                      cell.data.hasOwnProperty('hasChangeColor') &&
                      cell.data.ChangeColorRule
                    ) {
                      let value =
                        (item.value + '').toLowerCase() == 'true'
                          ? 1
                          : (item.value + '').toLowerCase() == 'false'
                          ? 0
                          : Number(item.value)
                      let rules = cell.data.ChangeColorRule.filter(
                        (z) =>
                          ((sceneType.value != 1 &&
                            z.deviceID == item.deviceID) ||
                            sceneType.value == 1) &&
                          z.parameName == item.parameName
                      )
                      if (rules != null && rules.length > 0)
                        rules.forEach((rule) => {
                          let maxValue =
                            (rule.maxValue + '').toLowerCase() == 'true'
                              ? 1
                              : (rule.maxValue + '').toLowerCase() == 'false'
                              ? 0
                              : Number(rule.maxValue)
                          let minValue =
                            (rule.minValue + '').toLowerCase() == 'true'
                              ? 1
                              : (rule.minValue + '').toLowerCase() == 'false'
                              ? 0
                              : Number(rule.minValue)
                          if (maxValue >= value && minValue <= value) {
                            let edge = graph.getCellById(cell.id)
                            edge.attr('line/stroke', rule.colorValue)
                          }
                        })
                    }
                  } //节点
                  else {
                    if (
                      cell.data.hasOwnProperty('hasShowHide') &&
                      cell.data.hasShowHide &&
                      cell.data.hasOwnProperty('ShowHideRule')
                    ) {
                      let value =
                        (item.value + '').toLowerCase() == 'true'
                          ? 1
                          : (item.value + '').toLowerCase() == 'false'
                          ? 0
                          : Number(item.value)
                      let rules = cell.data.ShowHideRule.filter(
                        (z) =>
                          ((sceneType.value != 1 &&
                            z.deviceID == item.deviceID) ||
                            sceneType.value == 1) &&
                          z.parameName == item.parameName
                      )
                      if (rules != null && rules.length > 0)
                        rules.forEach((rule) => {
                          let maxValue =
                            (rule.maxValue + '').toLowerCase() == 'true'
                              ? 1
                              : (rule.maxValue + '').toLowerCase() == 'false'
                              ? 0
                              : Number(rule.maxValue)
                          let minValue =
                            (rule.minValue + '').toLowerCase() == 'true'
                              ? 1
                              : (rule.minValue + '').toLowerCase() == 'false'
                              ? 0
                              : Number(rule.minValue)
                          if (maxValue >= value && minValue <= value) {
                            let node = graph.getCellById(cell.id)
                            node.visible = rule.type == '显示'
                          }
                        })
                    }
                    if (
                      cell.data.hasOwnProperty('hasRotate') &&
                      cell.data.hasRotate &&
                      cell.data.hasOwnProperty('RotateRule')
                    ) {
                      let value =
                        (item.value + '').toLowerCase() == 'true'
                          ? 1
                          : (item.value + '').toLowerCase() == 'false'
                          ? 0
                          : Number(item.value)
                      let rules = cell.data.RotateRule.filter(
                        (z) =>
                          ((sceneType.value != 1 &&
                            z.deviceID == item.deviceID) ||
                            sceneType.value == 1) &&
                          z.parameName == item.parameName
                      )
                      if (rules != null && rules.length > 0)
                        rules.forEach((rule) => {
                          let maxValue =
                            (rule.maxValue + '').toLowerCase() == 'true'
                              ? 1
                              : (rule.maxValue + '').toLowerCase() == 'false'
                              ? 0
                              : Number(rule.maxValue)
                          let minValue =
                            (rule.minValue + '').toLowerCase() == 'true'
                              ? 1
                              : (rule.minValue + '').toLowerCase() == 'false'
                              ? 0
                              : Number(rule.minValue)
                          if (maxValue >= value && minValue <= value) {
                            let node = graph.getCellById(cell.id)
                            let view = graph.findView(node)
                            if (rule.type == '旋转') {
                              if (
                                node.data.hasOwnProperty('isRotate') &&
                                node.data.isRotate
                              ) {
                              } else {
                                node.data.stopMethod = view.animateTransform(
                                  'image',
                                  {
                                    attributeType: 'XML',
                                    attributeName: 'transform',
                                    type: 'rotate',
                                    from:
                                      '0 ' +
                                      node.size().width / 2 +
                                      ' ' +
                                      node.size().height / 2,
                                    to:
                                      '360 ' +
                                      node.size().width / 2 +
                                      ' ' +
                                      node.size().height / 2,
                                    dur: '1s',
                                    repeatCount: 'indefinite'
                                  }
                                )
                                node.data.isRotate = true
                              }
                            } else if (rule.type == '静止') {
                              if (
                                node.data.hasOwnProperty('isRotate') &&
                                node.data.isRotate
                              ) {
                                node.data.stopMethod()
                                node.data.isRotate = false
                              }
                            }
                          }
                        })
                    }
                    if (
                      cell.data.hasOwnProperty('hasBind') &&
                      cell.data.hasBind &&
                      cell.data.hasOwnProperty('BindRule')
                    ) {
                      if (
                        ((sceneType.value != 1 &&
                          cell.data.BindRule.deviceID == item.deviceID) ||
                          sceneType.value == 1) &&
                        cell.data.BindRule.dataPointName == item.name
                      ) {
                        let node = graph.getCellById(cell.id)
                        node.attr('text/text', item.value)
                      }
                    }
                    if (
                      cell.data.hasOwnProperty('hasChangeColor') &&
                      cell.data.hasChangeColor &&
                      cell.data.hasOwnProperty('ChangeColorRule') &&
                      cell.data.ChangeColorRule
                    ) {
                      let value =
                        (item.value + '').toLowerCase() == 'true'
                          ? 1
                          : (item.value + '').toLowerCase() == 'false'
                          ? 0
                          : Number(item.value)
                      let rules = cell.data.ChangeColorRule.filter(
                        (z) =>
                          ((sceneType.value != 1 &&
                            z.deviceID == item.deviceID) ||
                            sceneType.value == 1) &&
                          z.parameName == item.parameName
                      )
                      if (rules != null && rules.length > 0)
                        rules.forEach((rule) => {
                          let maxValue =
                            (rule.maxValue + '').toLowerCase() == 'true'
                              ? 1
                              : (rule.maxValue + '').toLowerCase() == 'false'
                              ? 0
                              : Number(rule.maxValue)
                          let minValue =
                            (rule.minValue + '').toLowerCase() == 'true'
                              ? 1
                              : (rule.minValue + '').toLowerCase() == 'false'
                              ? 0
                              : Number(rule.minValue)

                          if (maxValue >= value && minValue <= value) {
                            let node = graph.getCellById(cell.id)
                            node.attr('body/fill', rule.colorValue)
                          }
                        })
                    }
                    if (
                      cell.data.hasOwnProperty('hasChangeWH') &&
                      cell.data.hasChangeWH &&
                      cell.data.hasOwnProperty('ChangeWHRule') &&
                      cell.data.ChangeWHRule
                    ) {
                      let value = parseFloat(item.value)
                      let rules = cell.data.ChangeWHRule.filter(
                        (z) =>
                          ((sceneType.value != 1 &&
                            z.deviceID == item.deviceID) ||
                            sceneType.value == 1) &&
                          z.parameName == item.parameName
                      )
                      if (rules != null && rules.length > 0)
                        rules.forEach((rule) => {
                          let maxValue = parseFloat(rule.maxValue)
                          let minValue = parseFloat(rule.minValue)
                          let ratio =
                            value == 0 ? 0 : value / (maxValue - minValue)

                          let node = graph.getCellById(cell.id)

                          if (rule.type == '高度') {
                            let newHeight = node.data.baseHeight * ratio
                            let oldSize = node.size()
                            if (rule.direction == '向下')
                              node.resize(
                                parseInt(oldSize.width),
                                parseInt(newHeight),
                                { direction: 'top' }
                              )
                            else if (rule.direction == '向上') {
                              node.resize(
                                parseInt(oldSize.width),
                                parseInt(newHeight),
                                { direction: 'bottom' }
                              )
                            }
                          } else if (rule.type == '宽度') {
                            let newWidth = node.data.baseWidth * ratio
                            let oldSize = node.size()
                            if (rule.direction == '向左')
                              node.resize(
                                parseInt(newWidth),
                                parseInt(oldSize.height),
                                { direction: 'right' }
                              )
                            else if (rule.direction == '向右')
                              node.resize(
                                parseInt(newWidth),
                                parseInt(oldSize.height),
                                { direction: 'left' }
                              )
                          }
                        })
                    }
                  }
                })
              }
            }
          }
        })
        if (tipMessage != '') {
          if (notiInfo.value != '') {
            if (tipMessage != notiInfo.value) {
              ElNotification.closeAll()
              notiInfo.value = tipMessage
              ElNotification({
                title: '预报警提醒',
                message: tipMessage,
                type: 'error',
                customClass: 'tipInfo',
                duration: 0,
                showClose: false
              })
            }
          } else {
            notiInfo.value = tipMessage
            ElNotification({
              title: '预报警提醒',
              message: tipMessage,
              type: 'error',
              customClass: 'tipInfo',
              duration: 0,
              showClose: false
            })
          }
        } else {
          notiInfo.value = ''
          ElNotification.closeAll()
        }
        if (graphDialogLinks != graphDialogLinks_New) {
          graphDialogLinks = graphDialogLinks_New
          if (graphDialogLinks_New != '') {
            graphIframeDialogContent.form = {
              title: graphInfo.dialogRules.form.title,
              width: graphInfo.dialogRules.form.width,
              height: graphInfo.dialogRules.form.height
            }
            GraphIframeDialogInfo.value.content = JSON.parse(
              JSON.stringify(graphIframeDialogContent)
            )
            let length = GraphIframeDialogInfo.value.content.rules.length
            radio.value = length == 1 ? 1 : length < 4 ? 2 : length < 9 ? 3 : 4
            if (!GraphIframeDialogInfo.value.visible)
              GraphIframeDialogInfo.value.visible = true
          } else GraphIframeDialogInfo.value.visible = false
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
window.onresize = () => {
  let width = parseInt(app.value.offsetWidth)
  let height = parseInt(app.value.offsetHeight)
  graph.resize(width, height)
  graph.scaleContentToFit()
  graph.centerContent()
}
const deviceID = ref('')
const sceneType = ref(0)
var token = ''
//公共方法
onMounted(() => {
  insertCss(
    `@keyframes ant-line {to{stroke-dashoffset: -1000}}@keyframes rotateAll {0%{transform: rotate(0deg);transform-origin:50% 50%;}50%{transform: rotate(180deg);transform-origin:50% 50%;}100%{transform: rotate(360deg);transform-origin:50% 50%;}}`
  )

  initGraph(true)
})
let scenceCode = ''
const initGraph = (initCode) => {
  dataCells = []
  pointPrames = []
  pointDataCache = []
  IframeDialogInfo.value = {
    visible: false,
    content: {}
  }
  if (JSON.stringify(graph) != '{}') {
    graph.dispose()
  }
  insertCss(`
  @keyframes ant-line {
    to {
        stroke-dashoffset: -1000
    }
  }
  @keyframes rotateAll {
    0%{transform: rotate(0deg);transform-origin:50% 50%;}
    50%{transform: rotate(180deg);transform-origin:50% 50%;}
    100%{transform: rotate(360deg);transform-origin:50% 50%;}
}
`)

  init()
  if (initCode) {
    if (typeof route.query.deviceID != 'undefined')
      deviceID.value = route.query.deviceID
    else if (props.deviceID != '') deviceID.value = props.deviceID
    if (typeof route.query.type != 'undefined')
      sceneType.value = route.query.type
    else if (props.type != 0) sceneType.value = props.type
    if (typeof route.query.code != 'undefined') scenceCode = route.query.code
    else if (props.code != '') scenceCode = props.code
    if (typeof route.query.token != 'undefined') token = route.query.token
  }

  if (scenceCode != '') {
    let params = new FormData()
    params.append('code', scenceCode)

    GetScenesInfo(params, { Authorization: token })
      .then((res) => {
        // document.title = res.Data.Name
        if (!isNull(res.Data.SceneInfo)) {
          try {
            let cesllInfo = JSON.parse(
              res.Data.SceneInfo.replace(regUrl, window.global_config.BASE_URL)
            )
            graphInfo = JSON.parse(res.Data.SceneGraph)
            initGraphInfo(cesllInfo)
            resetGraph()
            cleanData(cesllInfo)
            getPointData()
            intervalId.value = setInterval(() => {
              getPointData()
            }, 1000)
          } catch (e) {
            console.log(e)
          }
        }
      })
      .catch((err) => {
        console.log('获取场景信息异常:' + err)
      })
  }
}
onUnmounted(() => {
  GraphIframeDialogInfo.value.visible = false
  IframeDialogInfo.visible = false
  ElNotification.closeAll()
  clearInterval(intervalId.value)
  if (JSON.stringify(graph) != '{}') {
    graph.dispose()
  }
})
watch(
  () => route,
  () => {
    GraphIframeDialogInfo.value.visible = false
    IframeDialogInfo.visible = false
    ElNotification.closeAll()
    clearInterval(intervalId.value)
    initGraph(true)
  },
  {
    deep: true
  }
)
watch(
  () => props.code,
  () => {
    GraphIframeDialogInfo.value.visible = false
    IframeDialogInfo.visible = false
    ElNotification.closeAll()
    clearInterval(intervalId.value)
    initGraph(true)
  },
  {
    deep: true
  }
)
watch(
  () => props.deviceID,
  () => {
    GraphIframeDialogInfo.value.visible = false
    IframeDialogInfo.visible = false
    ElNotification.closeAll()
    clearInterval(intervalId.value)
    initGraph(true)
  },
  {
    deep: true
  }
)
const handleCloseAlert = (e) => {
  GraphIframeDialogInfo.value.visible = false
}
</script>
<style lang="scss" scoped src="@/styles/elementReset.scss"></style>
<style lang="scss" scoped src="./assets/index.scss"></style>
